@header-height: 64px;
@footer-height: 4em;
@sider-width: 0em;


.Footer {
  height: @footer-height;
}

.Header {
  top: 0;
  height: @header-height;
  background-color: "grey";
  position: fixed;
  width: 100%;
  z-index: 1;
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.Content {
  width: 100%;
  min-height: calc(100vh - @footer-height) !important;
}

.Outer {
  width: 100%;
}
@border-width-base: 0px;@border-radius-base: 16px;@border-radius-sm: 12px;@primary-color: #127ABA;@card-padding-base: 16px;@card-padding-sm: 8px;@component-background: #FFFFFF;@input-bg: #F0F2F5;@checkbox-check-bg: #F0F2F5;@checkbox-check-color: #fff;@font-family: Inter;@btn-font-weight: bold;@btn-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-primary-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-ghost-shadow: ;@btn-text-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@typography-title-font-weight: 700;@typography-title-margin-bottom: 0px;