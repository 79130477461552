@import (reference) '../../index.less';

.Tag {
	padding: 6px 12px !important;
	line-height: @font-size-sm !important;
	border-style: none !important;
	margin: 0 !important;
}

.TagBold {
	font-weight: bold;
}
@border-width-base: 0px;@border-radius-base: 16px;@border-radius-sm: 12px;@primary-color: #127ABA;@card-padding-base: 16px;@card-padding-sm: 8px;@component-background: #FFFFFF;@input-bg: #F0F2F5;@checkbox-check-bg: #F0F2F5;@checkbox-check-color: #fff;@font-family: Inter;@btn-font-weight: bold;@btn-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-primary-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-ghost-shadow: ;@btn-text-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@typography-title-font-weight: 700;@typography-title-margin-bottom: 0px;