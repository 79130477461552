@import (reference) '../../App.less';

.CurrentPlanTag {
	font-size: 16px !important;
	font-weight: bold !important;
	padding: 8px 16px 8px 16px !important;
}

.CurrentPlanTagClickable {
	font-size: 16px !important;
	font-weight: bold !important;
	padding: 8px 16px 8px 16px !important;
	box-shadow: @btn-primary-shadow;
}

.CurrentPlanTagClickable:hover {
	font-size: 16px !important;
	font-weight: bold !important;
	padding: 8px 16px 8px 16px !important;
	cursor: pointer;
	box-shadow: 0 6px 16px 1px rgb(0 0 0 / 0.12)
}
@border-width-base: 0px;@border-radius-base: 16px;@border-radius-sm: 12px;@primary-color: #127ABA;@card-padding-base: 16px;@card-padding-sm: 8px;@component-background: #FFFFFF;@input-bg: #F0F2F5;@checkbox-check-bg: #F0F2F5;@checkbox-check-color: #fff;@font-family: Inter;@btn-font-weight: bold;@btn-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-primary-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-ghost-shadow: ;@btn-text-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@typography-title-font-weight: 700;@typography-title-margin-bottom: 0px;