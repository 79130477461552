@import (reference) '../../index.less';

.Box {
	background-color: @component-background;
	width: calc(100% + 16px);
	padding-right: 16px;
	min-height: 480px;
	overflow-y: scroll;
	margin-bottom: 8px;
	border-radius: @border-radius-base;
	transition: width 0.3s ease-in-out, padding-right 0.3s ease-in-out;
	// border-color: @border-color-base;
	// border-width: @border-width-base;
	// border-style: @border-style-base;
}

.Box:hover {
	width: 100%;
	padding-right: 0px;
	transition: width 0.3s ease-in-out, padding-right 0.3s ease-in-out;
}

.BoxWrapper {
	overflow: hidden;
	margin-bottom: 16px;
}

.Seperator {
	margin: 16px 0 0 0;
}

.BubbleSeperator {
	border-radius: @border-radius-base;
	background-color: #F0F2F5;
	margin: 16px 0;
;
}

@border-width-base: 0px;@border-radius-base: 16px;@border-radius-sm: 12px;@primary-color: #127ABA;@card-padding-base: 16px;@card-padding-sm: 8px;@component-background: #FFFFFF;@input-bg: #F0F2F5;@checkbox-check-bg: #F0F2F5;@checkbox-check-color: #fff;@font-family: Inter;@btn-font-weight: bold;@btn-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-primary-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-ghost-shadow: ;@btn-text-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@typography-title-font-weight: 700;@typography-title-margin-bottom: 0px;