@import (reference) '../../index.less';

.UserCardSmall {
	max-width: 150px;
	max-height: 64px;
}

.UserCardMedium {
	max-width: 300px;
	max-height: 150px;
}
@border-width-base: 0px;@border-radius-base: 16px;@border-radius-sm: 12px;@primary-color: #127ABA;@card-padding-base: 16px;@card-padding-sm: 8px;@component-background: #FFFFFF;@input-bg: #F0F2F5;@checkbox-check-bg: #F0F2F5;@checkbox-check-color: #fff;@font-family: Inter;@btn-font-weight: bold;@btn-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-primary-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-ghost-shadow: ;@btn-text-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@typography-title-font-weight: 700;@typography-title-margin-bottom: 0px;