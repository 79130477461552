
.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 5;
	padding: 128px 10% 0 10%;
	margin: auto;
	overflow-y: auto;
}

.HelpButton {
	z-index: 6;
	position: fixed;
	right: -16px;
	bottom: 32px;
	overflow: visible;
}


@border-width-base: 0px;@border-radius-base: 16px;@border-radius-sm: 12px;@primary-color: #127ABA;@card-padding-base: 16px;@card-padding-sm: 8px;@component-background: #FFFFFF;@input-bg: #F0F2F5;@checkbox-check-bg: #F0F2F5;@checkbox-check-color: #fff;@font-family: Inter;@btn-font-weight: bold;@btn-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-primary-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-ghost-shadow: ;@btn-text-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@typography-title-font-weight: 700;@typography-title-margin-bottom: 0px;