@import (reference) './index.less';

.BoxShadow {
  // box-shadow: 0 5px 15px -0px rgb(9 61 93 / 8%), 0 15px 30px -0px rgb(9 61 93 / 5%), 0 20px 45px -0px rgb(9 61 93 / 4%);
  box-shadow: 0px 28px 28px rgba(9, 61, 93, 0.04), 0px 3px 15px rgba(9, 61, 93, 0.05), 0px 0px 0px rgba(9, 61, 93, 0.05);
  transition: box-shadow 0.3s ease !important;
}

.BoxShadow:hover {
	// box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%) !important;
  // box-shadow: 0 2px 8px 2px rgb(9 61 93 / 10%), 0 8px 15px 4px rgb(9 61 93 / 7%), 0 10px 20px 6px rgb(9 61 93 / 5%);
  box-shadow: 0px 6px 12px rgba(9, 61, 93, 0.13), 0px 3px 6px rgba(9, 61, 93, 0.15), 0px 2px 3px rgba(9, 61, 93, 0.15);
}

.Thin {
  max-width: 400px;
  margin: auto;
  width: auto;
}

.Narrow {
  max-width: 1000px !important;
  margin: auto;
  width: 100%;
}

.Wide {
  width: auto;
  max-width: 1400px;
  margin: auto;
}

.Clickable:hover {
  cursor: pointer;
}

.PageBackground {
  background-color: #F0F2F5;
}

.PagePadding {
  @media only screen and (max-width: @screen-xs) {
    padding: 16px;
  }
  @media only screen and (min-width: @screen-xs) and (max-width: @screen-md) {
    padding: 32px;
  }
  @media only screen and (min-width: @screen-md) {
    padding: 64px;
  }
}

.PageMargin {
  @media only screen and (max-width: @screen-xs) {
    margin: 16px;
  }
  @media only screen and (min-width: @screen-xs) and (max-width: @screen-md) {
    margin: 32px;
  }
  @media only screen and (min-width: @screen-md) {
    margin: 64px;
  }
}

.Page {
  &:extend(.PageBackground all);
  &:extend(.PagePadding all);
  width: 100%;
  min-height: 100vh;
}

@border-width-base: 0px;@border-radius-base: 16px;@border-radius-sm: 12px;@primary-color: #127ABA;@card-padding-base: 16px;@card-padding-sm: 8px;@component-background: #FFFFFF;@input-bg: #F0F2F5;@checkbox-check-bg: #F0F2F5;@checkbox-check-color: #fff;@font-family: Inter;@btn-font-weight: bold;@btn-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-primary-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-ghost-shadow: ;@btn-text-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@typography-title-font-weight: 700;@typography-title-margin-bottom: 0px;