// @import (reference) '../../App.less';

.color-input {
	position: relative;
	&__color {
		width: 20px;
		height: 20px;
		border-radius: 8px;
		cursor: pointer;
		border-color: @input-bg;
		border-style: solid;
		border-width: 2px;
	}
	&__picker {
		position: absolute;
		top: 100%;
		right: 0;
		z-index: 1;
		border-radius: 16px;
	}
}
@border-width-base: 0px;@border-radius-base: 16px;@border-radius-sm: 12px;@primary-color: #127ABA;@card-padding-base: 16px;@card-padding-sm: 8px;@component-background: #FFFFFF;@input-bg: #F0F2F5;@checkbox-check-bg: #F0F2F5;@checkbox-check-color: #fff;@font-family: Inter;@btn-font-weight: bold;@btn-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-primary-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-ghost-shadow: ;@btn-text-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@typography-title-font-weight: 700;@typography-title-margin-bottom: 0px;