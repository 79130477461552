@import (reference) '~antd/lib/style/themes/default.less';
@import (reference) '~antd/dist/antd.less'; // Import Ant Design styles by less entry
// @import (reference) './antd.customize.less'; // variables to override above

@font-face {
	font-family: 'Inter';
	font-weight: 100;
	src: local('Inter'), url('./fonts/inter/static/Inter-Thin.ttf') format('truetype');
}
@font-face {
	font-family: 'Inter';
	font-weight: 300;
	src: local('Inter'), url('./fonts/inter/static/Inter-Light.ttf') format('truetype');
}
@font-face {
	font-family: 'Inter';
	font-weight: 500;
	src: local('Inter'), url('./fonts/inter/static/Inter-Medium.ttf') format('truetype');
}
@font-face {
	font-family: 'Inter';
	font-weight: 400;
	src: local('Inter'), url('./fonts/inter/static/Inter-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'Inter';
	font-weight: 200;
	src: local('Inter'), url('./fonts/inter/static/Inter-ExtraLight.ttf') format('truetype');
}
@font-face {
	font-family: 'Inter';
	font-weight: 600;
	src: local('Inter'), url('./fonts/inter/static/Inter-SemiBold.ttf') format('truetype');
}
@font-face {
	font-family: 'Inter';
	font-weight: 700;
	src: local('Inter'), url('./fonts/inter/static/Inter-Bold.ttf') format('truetype');
}
@font-face {
	font-family: 'Inter';
	font-weight: 800;
	src: local('Inter'), url('./fonts/inter/static/Inter-ExtraBold.ttf') format('truetype');
}
@font-face {
	font-family: 'Inter';
	font-weight: 900;
	src: local('Inter'), url('./fonts/inter/static/Inter-Black.ttf') format('truetype');
}

@background-color: #F0F2F5;
@primary-color: #127ABA;

input {
	background-color: @background-color;
}
@border-width-base: 0px;@border-radius-base: 16px;@border-radius-sm: 12px;@primary-color: #127ABA;@card-padding-base: 16px;@card-padding-sm: 8px;@component-background: #FFFFFF;@input-bg: #F0F2F5;@checkbox-check-bg: #F0F2F5;@checkbox-check-color: #fff;@font-family: Inter;@btn-font-weight: bold;@btn-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-primary-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@btn-ghost-shadow: ;@btn-text-shadow: 0 3px 12px -0px rgb(0 0 0 / 0.08);@typography-title-font-weight: 700;@typography-title-margin-bottom: 0px;